import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** Styling */
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
/** Local File Imports */
import EnhancedTableToolbar from './EnhancedTableToolbar';
// Disabling the eslint warning for import/no-named-as-default because there is no other export from the EnhancedTableHead.js file
// eslint-disable-next-line import/no-named-as-default
import EnhancedTableHead from './EnhancedTableHead';
import getCampaigns from '../Api';

function desc(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

export function stableSort(array, cmp) {
   if (!array) {
      array = [];
   }
   const stabilizedCampaigns = array.map((el, index) => [el, index]);
   stabilizedCampaigns.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedCampaigns.map(el => el[0]);
}

export function getSorting(order, orderBy) {
   return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function searchByName(array, searchTerm) {
   return array.filter(item => {
      if (
         item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         item.updated_at.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
         return item;
      }
      return null;
   });
}

export const useStyle = makeStyles(theme => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   paper: {
      marginBottom: theme.spacing(2),
      width: '100%',
      boxShadow: 'none',
      padding: 0,
   },
   table: {
      width: '100%',
   },
   tableWrapper: {
      overflowX: 'auto',
   },
   visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
   },
   button: {
      margin: theme.spacing(1),
   },
}));

export default function SearchTable() {
   const classes = useStyle();
   const [order, setOrder] = useState('asc');
   const [orderBy, setOrderBy] = useState('campaign_id');
   const [page, setPage] = useState(0);
   const [dense, setDense] = useState(false);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [isSearch, setIsSearch] = useState(false);
   const [searchTerm, setSearchTerm] = useState('');
   const [selectedCampaigns, setSelectedCampaigns] = useState([]);
   const [allCampaigns, setAllCampaigns] = useState([]);
   const dispatch = useDispatch();
   let renderedSearchCampaigns = useSelector(state => state.searchCampaigns);

   if (!renderedSearchCampaigns) {
      renderedSearchCampaigns = [];
   }

   const onGetSuccess = response => {
      const allSuccessfulCampaigns = response.data.campaigns;
      dispatch({ type: 'SET_SEARCH_CAMPAIGNS', payload: allSuccessfulCampaigns });
      setAllCampaigns(allSuccessfulCampaigns);
   };

   const onGetError = error => {
      console.error('Error during Api call:', error.response);
   };

   useEffect(() => {
      getCampaigns(onGetSuccess, onGetError);
   }, []); // Empty array only calls function on first load.

   const onSearch = term => {
      setSearchTerm(term);
      const searchedCampaigns = searchByName(allCampaigns, term);
      dispatch({ type: 'SET_SEARCH_CAMPAIGNS', payload: searchedCampaigns });
   };

   const handleRequestSort = (event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
   };

   const handleSelectAllClick = event => {
      if (event.target.checked) {
         setSelectedCampaigns(renderedSearchCampaigns);
         return;
      }
      setSelectedCampaigns([]);
   };

   /** instead of passing campaign_id, pass the whole object */
   const onSelectCampaign = campaign => {
      // return if campaign_id matches
      const areSelected = selectedCampaigns.filter(selected => {
         return campaign.campaign_id === selected.campaign_id;
      });

      // if not in selectedCampaigns already, add it
      if (areSelected === undefined || areSelected.length === 0) {
         setSelectedCampaigns([...selectedCampaigns, campaign]);
      }
      // if it is in selectedCampaigns, replace with the new filtered array
      else {
         const filteredSelectedList = selectedCampaigns.filter(selected => {
            return campaign.campaign_id !== selected.campaign_id;
         });
         setSelectedCampaigns(filteredSelectedList);
      }
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const handleChangeDense = event => {
      setDense(event.target.checked);
   };

   const isSelected = campaignId => {
      return selectedCampaigns.some(selected => {
         return selected.campaign_id === campaignId;
      });
   };

   const emptyRows = rowsPerPage - Math.min(rowsPerPage, renderedSearchCampaigns.length - page * rowsPerPage);

   return (
      <div className={classes.root}>
         <Paper className={classes.paper}>
            <EnhancedTableToolbar
               selectedCampaigns={selectedCampaigns}
               setSelectedCampaigns={setSelectedCampaigns}
               numSelected={selectedCampaigns.length}
               isSearch={isSearch}
               setIsSearch={setIsSearch}
               searchTerm={searchTerm}
               onSearch={onSearch}
               numCampaigns={renderedSearchCampaigns.length}
            />
            <div className={classes.tableWrapper}>
               <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
               >
                  <EnhancedTableHead
                     classes={classes}
                     numSelected={selectedCampaigns.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={renderedSearchCampaigns.length}
                  />
                  <TableBody>
                     {stableSort(renderedSearchCampaigns, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((campaign, index) => {
                           const isItemSelected = isSelected(campaign.campaign_id);
                           const labelId = `enhanced-table-checkbox-${index}`;

                           return (
                              <TableRow
                                 hover
                                 onClick={() => onSelectCampaign(campaign)}
                                 role="checkbox"
                                 aria-checked={isItemSelected}
                                 tabIndex={-1}
                                 key={campaign.campaign_id}
                                 selected={isItemSelected}
                                 data-testid={`search-table-checkbox-${index}`}
                              >
                                 <TableCell padding="checkbox">
                                    <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                 </TableCell>
                                 <TableCell align="left">{campaign.name}</TableCell>
                                 <TableCell align="left">{campaign.updated_at}</TableCell>
                              </TableRow>
                           );
                        })}
                     {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </div>
            <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component="div"
               count={renderedSearchCampaigns.length}
               rowsPerPage={rowsPerPage}
               page={page}
               backIconButtonProps={{
                  'aria-label': 'previous page',
               }}
               nextIconButtonProps={{
                  'aria-label': 'next page',
                  'data-testid': 'search-table__page-change',
               }}
               onChangePage={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
               data-testid="search-table__page-and-row-change"
            />
         </Paper>
         <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
            data-testid="search-table__dense-padding"
         />
      </div>
   );
}
