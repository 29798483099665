import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ReportTable from './ReportTable';

const useStyle = makeStyles(theme => ({
   root: {
      '& > *': {
         margin: theme.spacing(1),
      },
   },
   formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
   },
   selectEmpty: {
      marginTop: theme.spacing(2),
   },
}));

export default function ReportDropdown(props) {
   const { numCampaigns } = props;
   const classes = useStyle();
   const [open, setOpen] = React.useState(false);

   const [reportCampaigns, setReport] = React.useState([]);

   const [fullWidth] = React.useState(true);
   const [maxWidth] = React.useState(false);

   const inputLabel = React.useRef(null);
   const [labelWidth, setLabelWidth] = React.useState(0);
   const [selectedMenu, setSelectedMenu] = React.useState('');
   React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
   }, []);

   const handleChange = event => {
      setSelectedMenu(event.target.value);
   };

   const handleClose = () => {
      setOpen(false);
   };
   const handleOpen = () => {
      postReport();
      setOpen(true);
   };

   async function postReport() {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const options = {
         method: 'POST',
         headers,
         body: JSON.stringify({
            campaigns: props.campaign_ids,
         }),
      };
      const ReportEndPoint = `http://localhost/api/reports/${selectedMenu}`;
      const request = new Request(ReportEndPoint, options);
      const response = await fetch(request);
      const jsoned = await response.json();
      if (jsoned instanceof Object) {
         const results = [];
         jsoned.activity_report.map(campaign =>
            Object.keys(campaign).map(key =>
               campaign[key].map(campaignReport => {
                  return results.push(campaignReport);
               }),
            ),
         );
         return setReport(results);
      }
      return setReport(jsoned);
   }

   return (
      <div className={classes.root}>
         <Grid container direction="row" alignItems="center">
            <Grid item>
               <FormControl
                  data-testid="report-dropdown__dropdown"
                  variant="outlined"
                  className={classes.formControl}
                  aria-disabled={!numCampaigns}
                  disabled={!numCampaigns}
               >
                  <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                     Action Report
                  </InputLabel>
                  <Select
                     labelId="demo-simple-select-outlined-label"
                     id="demo-simple-select-outlined"
                     value={selectedMenu}
                     onChange={handleChange}
                     labelWidth={labelWidth}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     <MenuItem value="bounces">Bounces</MenuItem>
                     <MenuItem value="clicks">Clicks</MenuItem>
                     <MenuItem value="didnotopens">Did Not Opens</MenuItem>
                     <MenuItem value="forwards">Forwards</MenuItem>
                     <MenuItem value="opens">Opens</MenuItem>
                     <MenuItem value="optouts">Opt-outs</MenuItem>
                     <MenuItem value="unique_opens">Unique Opens</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
            <Grid item>
               <Button
                  data-testid="report-dropdown__preview-button"
                  variant="outlined"
                  onClick={handleOpen}
                  hidden={selectedMenu === ''}
                  aria-disabled={selectedMenu === ''}
                  disabled={selectedMenu === ''}
               >
                  Preview
               </Button>
               <Dialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
                  <Grid className={classes.form}>
                     <ReportTable data={reportCampaigns} />
                  </Grid>
               </Dialog>
            </Grid>
            <Grid item>
               <Button
                  data-testid="report-dropdown__export-to-csv-button"
                  variant="outlined"
                  aria-disabled={selectedMenu === ''}
                  disabled={selectedMenu === ''}
               >
                  Export to CSV
               </Button>
            </Grid>
         </Grid>
      </div>
   );
}
