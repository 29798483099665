/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const useToolbarStyle = makeStyles(theme => ({
   root: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
   },
   highlight:
      theme.palette.type === 'light'
         ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
           }
         : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
           },
   title: {
      flex: '0 auto',
      marginRight: theme.spacing(2),
   },
   numCampaigns: {
      paddingLeft: theme.spacing(2),
   },
   numSelect: {
      marginRight: theme.spacing(2),
   },
   textField: {},
}));

const EnhancedTableToolbar = props => {
   const { setSelectedCampaigns, selectedCampaigns, searchTerm, onSearch, numCampaigns, numSelected } = props;
   const classes = useToolbarStyle();
   const fullSearchCampaigns = useSelector(state => state.searchCampaigns);
   const fullExportCampaigns = useSelector(state => state.exportCampaigns);
   const dispatch = useDispatch();

   const exportCampaigns = () => {
      // filter selected from export campaigns
      const currentExportCampaigns = fullExportCampaigns.filter(campaign => {
         return !selectedCampaigns.some(selected => {
            return campaign.campaign_id === selected.campaign_id;
         });
      });

      // filter selected from search campaigns
      const filteredSearchCampaigns = fullSearchCampaigns.filter(campaign => {
         return !selectedCampaigns.some(selected => {
            return campaign.campaign_id === selected.campaign_id;
         });
      });

      // copy selected to exported campaigns
      dispatch({ type: 'SET_EXPORT_CAMPAIGNS', payload: selectedCampaigns.concat(currentExportCampaigns) });

      // dispatch to searchCampaigns
      dispatch({ type: 'SET_SEARCH_CAMPAIGNS', payload: filteredSearchCampaigns });

      // set selected to empty array
      setSelectedCampaigns([]);
   };

   return (
      <>
         <Toolbar
            className={clsx(classes.root, {
               [classes.highlight]: numSelected > 0,
            })}
         >
            <Typography className={classes.title} variant="h6" id="tableTitle">
               Campaigns
            </Typography>
            <Typography className={classes.numSelect} variant="subtitle1" hidden={!numSelected}>
               {numSelected} selected
            </Typography>
            <Box display="flex" flexDirection="column" marginLeft="auto">
               <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" my={1}>
                  <Tooltip title="Search">
                     <TextField
                        id="outlined-search"
                        data-testid="enhanced-table-toolbar__search-text"
                        label="Search"
                        type="text"
                        className={classes.textField}
                        margin="none"
                        variant="outlined"
                        value={searchTerm}
                        onChange={e => onSearch(e.target.value)}
                        InputProps={{ style: { paddingRight: 40 } }}
                     />
                  </Tooltip>
                  <IconButton
                     style={{ marginLeft: -50 }}
                     onClick={() => onSearch('')}
                     data-testid="enhanced-table-toolbar__clear-search-button"
                  >
                     <CloseIcon />
                  </IconButton>
               </Box>
               <Button
                  data-testid="enhanced-table-toolbar__button-export"
                  onClick={exportCampaigns}
                  variant="outlined"
                  disabled={numSelected === 0}
               >
                  Add to Export List
               </Button>
            </Box>
         </Toolbar>
         <Typography className={classes.numCampaigns} variant="body1">
            {numCampaigns} campaigns
         </Typography>
      </>
   );
};

EnhancedTableToolbar.propTypes = {
   numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
