import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useStyle, getSorting, stableSort } from './search/SearchTable';
import EnhancedTableHead from './search/EnhancedTableHead';
import EnhancedExportTableToolbar from './EnhancedExportTableToolbar'; // Import a component from another file

export default function ExportTable() {
   const classes = useStyle();
   const [order, setOrder] = useState('asc');
   const [orderBy, setOrderBy] = useState('campaign_id');
   const [page, setPage] = useState(0);
   const [dense, setDense] = useState(false);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [selectedCampaigns, setSelectedCampaigns] = useState([]);
   const exportCampaigns = useSelector(state => state.exportCampaigns);

   const handleRequestSort = (event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
   };

   const handleSelectAllClick = event => {
      if (event.target.checked) {
         setSelectedCampaigns(exportCampaigns);
         return;
      }
      setSelectedCampaigns([]);
   };

   /** instead of passing campaign_id, pass the whole object */
   const onSelectCampaign = campaign => {
      // return if campaign_id matches
      const selected = selectedCampaigns.filter(newSelected => {
         return campaign.campaign_id === newSelected.campaign_id;
      });

      // if not in selectedCampaigns already, add it
      if (selected === undefined || selected.length === 0) {
         setSelectedCampaigns([...selectedCampaigns, campaign]);
      }
      // if it is in selectedCampaigns, replace with the new filtered array
      else {
         const filteredSelectedList = selectedCampaigns.filter(newSelected => {
            return campaign.campaign_id !== newSelected.campaign_id;
         });
         setSelectedCampaigns(filteredSelectedList);
      }
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const handleChangeDense = event => {
      setDense(event.target.checked);
   };

   const isSelected = campaignId => {
      return selectedCampaigns.some(selected => {
         return selected.campaign_id === campaignId;
      });
   };

   const emptyRows = rowsPerPage - Math.min(rowsPerPage, exportCampaigns.length - page * rowsPerPage);

   return (
      <div className={classes.root}>
         <Paper className={classes.paper}>
            <EnhancedExportTableToolbar
               setSelectedCampaigns={setSelectedCampaigns}
               selectedCampaigns={selectedCampaigns}
               numSelected={selectedCampaigns.length}
               numCampaigns={exportCampaigns.length}
               campaign_ids={exportCampaigns.map(({ campaignId }) => campaignId)}
            />
            <div className={classes.tableWrapper}>
               <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
               >
                  <EnhancedTableHead
                     classes={classes}
                     numSelected={selectedCampaigns.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={exportCampaigns.length}
                  />
                  <TableBody>
                     {stableSort(exportCampaigns, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((campaign, index) => {
                           const isItemSelected = isSelected(campaign.campaign_id);
                           const labelId = `enhanced-table-checkbox-${index}`;

                           return (
                              <TableRow
                                 hover
                                 onClick={() => onSelectCampaign(campaign)}
                                 role="checkbox"
                                 aria-checked={isItemSelected}
                                 tabIndex={-1}
                                 key={campaign.campaign_id}
                                 selected={isItemSelected}
                                 data-testid={`export-table-checkbox-${index}`}
                              >
                                 <TableCell padding="checkbox">
                                    <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                 </TableCell>
                                 <TableCell align="left">{campaign.name}</TableCell>
                                 <TableCell align="left">{campaign.updated_at}</TableCell>
                              </TableRow>
                           );
                        })}
                     {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </div>
            <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component="div"
               count={exportCampaigns.length}
               rowsPerPage={rowsPerPage}
               page={page}
               backIconButtonProps={{
                  'aria-label': 'previous page',
               }}
               nextIconButtonProps={{
                  'aria-label': 'next page',
                  'data-testid': 'export-table__page-change',
               }}
               onChangePage={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
               data-testid="export-table__page-and-row-change"
            />
         </Paper>
         <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
            data-testid="export-table__dense-padding"
         />
      </div>
   );
}

EnhancedTableHead.propTypes = {
   // eslint-disable-next-line react/forbid-prop-types
   classes: PropTypes.object.isRequired,
   numSelected: PropTypes.number.isRequired,
   onRequestSort: PropTypes.func.isRequired,
   onSelectAllClick: PropTypes.func.isRequired,
   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
   orderBy: PropTypes.string.isRequired,
   rowCount: PropTypes.number.isRequired,
};
