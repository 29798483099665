import React from 'react';
import MaterialTable from 'material-table';

export default function ReportTable({ data }) {
   const dateTime = Date.now();
   const timeStamp = Math.floor(dateTime / 1000);

   return (
      <MaterialTable
         title="Report Table"
         columns={[
            { title: 'Campaign Name', field: 'name' },
            { title: 'First Name', field: 'first_name' },
            { title: 'Last Name', field: 'last_name' },
            { title: 'Email Address', field: 'email_address' },
            { title: 'Company', field: 'company_name' },
            { title: 'Date/Time', field: 'created_time' },
         ]}
         isLoading={data.length === 0}
         localization={{
            body: {
               emptyDataSourceMessage: data.length === 0 ? 'Loading...' : 'No available reports',
            },
         }}
         data={data}
         options={{
            search: false,
            exportButton: true,
            exportFileName: `clicked_${String(timeStamp)}`,
         }}
      />
   );
}
