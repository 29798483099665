const initialState = [];

const searchCampaigns = (state = initialState, action) => {
   if (action.type === 'SET_SEARCH_CAMPAIGNS') {
      return action.payload;
   }
   return state;
};

export default searchCampaigns;
