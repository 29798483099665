import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ReportDropdown from './report/ReportDropdown';

const useToolbarStyles = makeStyles(theme => ({
   root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      margin: theme.spacing(1),
   },
   title: {
      flex: '0 auto',
      marginRight: theme.spacing(2),
   },
   numCampaigns: {
      paddingLeft: theme.spacing(2),
   },
   numSelect: {
      marginRight: theme.spacing(2),
   },
   exportButton: {
      marginRight: theme.spacing(4),
   },
}));

const EnhancedExportTableToolbar = props => {
   const { setSelectedCampaigns, selectedCampaigns, numCampaigns, numSelected } = props;
   const classes = useToolbarStyles();
   const fullSearchCampaigns = useSelector(state => state.searchCampaigns);
   const fullExportCampaigns = useSelector(state => state.exportCampaigns);
   const dispatch = useDispatch();

   const clearCampaigns = () => {
      // filter selected from search campaigns
      const currentSearchCampaigns = fullSearchCampaigns.filter(campaign => {
         return !selectedCampaigns.some(selected => {
            return campaign.campaign_id === selected.campaign_id;
         });
      });

      // filter selected from export campaigns
      const filteredExportCampaigns = fullExportCampaigns.filter(campaign => {
         return !selectedCampaigns.some(selected => {
            return campaign.campaign_id === selected.campaign_id;
         });
      });

      // copy selected to searchCampaigns
      dispatch({
         type: 'SET_SEARCH_CAMPAIGNS',
         payload: selectedCampaigns.concat(currentSearchCampaigns),
      });

      // dispatch to exportCampaigns
      dispatch({
         type: 'SET_EXPORT_CAMPAIGNS',
         payload: filteredExportCampaigns,
      });

      // set selected to empty array
      setSelectedCampaigns([]);
   };

   return (
      <>
         <Toolbar className={classes.root}>
            <ReportDropdown campaign_ids={props} numCampaigns={numCampaigns} />
            <Box display="flex" flexDirection="column" marginLeft="auto">
               <Button
                  data-testid="EnhancedExportTable__Button-Export"
                  onClick={clearCampaigns}
                  variant="outlined"
                  disabled={!numSelected}
               >
                  Remove From Export List
               </Button>
            </Box>
         </Toolbar>
         <br />
         <p />
         <Typography className={classes.numCampaigns} variant="body1">
            {numSelected ? (
               <Typography className={classes.numSelect} variant="body1">
                  {`${numCampaigns} campaigns - ${numSelected} selected`}
               </Typography>
            ) : (
               <Typography className={classes.numSelect} variant="body1">
                  {`${numCampaigns} campaigns`}
               </Typography>
            )}
         </Typography>
      </> // <br> and <p> are for spacing to make the UI look even on both sides
   );
};

EnhancedExportTableToolbar.propTypes = {
   numSelected: PropTypes.number.isRequired,
};

export default EnhancedExportTableToolbar;
