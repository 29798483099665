import { combineReducers } from 'redux';
import exportCampaigns from './exportCampaigns';
import searchCampaigns from './searchCampaigns';

const allReducers = combineReducers({
   exportCampaigns,
   searchCampaigns,
});

export default allReducers;
