import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

const PageContent = ({ children }) => {
   const styles = {
      backgroundColor: '#ecf0f1',
      height: '100vh',
      width: '100vw',
   };
   return (
      <>
         <CssBaseline />
         <div style={styles}>{children}</div>
      </>
   );
};

export default PageContent;
