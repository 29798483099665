import React from 'react';
import './App.css';
import PageContent from './pageContent';
import Page from './Page';

class App extends React.PureComponent {
   render() {
      return (
         <PageContent>
            <Page />
         </PageContent>
      );
   }
}

export default App;
