const initialState = [];

const exportCampaigns = (state = initialState, action) => {
   if (action.type === 'SET_EXPORT_CAMPAIGNS') {
      return action.payload;
   }
   return state;
};

export default exportCampaigns;
