/* eslint-disable no-console */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import SearchPanel from './search/SearchPanel';
import ExportPanel from './ExportPanel';
import LoginButton from './components/LoginButton';
import ProfileMenu from './components/ProfileMenu';

function Copyright() {
   return (
      <Typography variant="body2" color="textSecondary" align="center">
         {'Copyright © '}
         <Link color="inherit" href="https://eldertree.biz/">
            Elder Tree
         </Link>
         {` 2019 - ${new Date().getFullYear()}.`}
      </Typography>
   );
}

const useStyles = makeStyles(theme => ({
   root: {
      display: 'flex',
   },
   appBar: {
      paddingRight: 24,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      display: 'flex',
      height: 32,
      justifyContent: 'center',
   },
   title: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
   },
   appBarSpacer: theme.mixins.toolbar,
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      marginTop: theme.spacing(2),
   },
   fixedHeight: {
      height: 240,
   },
   footer: {
      position: 'absolute',
      bottom: 10,
      left: '50%',
      transform: `translateX(-50%)`,
   },
}));

export default function Page() {
   const classes = useStyles();
   const { user, isLoading, isAuthenticated } = useAuth0();
   console.log('isAuthenticated', isAuthenticated);
   console.log('isAuthenticated', user);

   return (
      <div className={classes.root}>
         <AppBar position="absolute" className={classes.appBar} color="inherit">
            {isAuthenticated && (
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component="h1" variant="h6" color="textPrimary" noWrap className={classes.title}>
                     Report
                  </Typography>
                  <ProfileMenu />
               </div>
            )}
            {!isAuthenticated && !isLoading && <LoginButton />}
         </AppBar>
         <Container maxWidth="lg" style={{ paddingTop: 30 }}>
            {isAuthenticated && (
               <Grid container spacing={1}>
                  <Grid item xs={6}>
                     <SearchPanel />
                  </Grid>
                  <Grid item xs={6}>
                     <ExportPanel />
                  </Grid>
               </Grid>
            )}
            <div className={classes.footer}>
               <Copyright />
            </div>
         </Container>
      </div>
   );
}
