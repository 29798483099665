/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExportTable from './ExportTable';

export default function ExportPanel() {
   return (
      <>
         <Box width="100%">
            <Box p={1}>
               <Typography variant="h3">Export List</Typography>
            </Box>
            <Box my={0.5}>
               <ExportTable />
            </Box>
         </Box>
      </>
   );
}
