/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
   root: {
      display: 'flex',
   },
   paper: {
      marginRight: theme.spacing(2),
   },
   small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(1),
   },
}));

export default function ProfileMenu() {
   const classes = useStyles();
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef(null);
   const { logout, user } = useAuth0();

   const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
   };

   const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }

      setOpen(false);
   };

   function handleListKeyDown(event) {
      if (event.key === 'Tab') {
         event.preventDefault();
         setOpen(false);
      }
   }

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = React.useRef(open);
   React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
         anchorRef.current.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <div className={classes.root}>
         <div>
            <Button
               ref={anchorRef}
               aria-controls={open ? 'menu-list-grow' : undefined}
               aria-haspopup="true"
               onClick={handleToggle}
            >
               <Avatar alt="Remy Sharp" src={user.picture} className={classes.small} />
               {user.name}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
               {({ TransitionProps, placement }) => (
                  <Grow
                     {...TransitionProps}
                     style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                           <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem onClick={handleClose}>Link to Modal</MenuItem>
                              <MenuItem onClick={logout}>Logout</MenuItem>
                           </MenuList>
                        </ClickAwayListener>
                     </Paper>
                  </Grow>
               )}
            </Popper>
         </div>
      </div>
   );
}
