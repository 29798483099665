// api request
import axios from 'axios';

const Api = async (onSuccess, onError) => {
   const url = `/api/campaigns`;

   // prettier-ignore
   await axios
      .get(url, { withCredentials: true })
      .then(onSuccess)
      .catch(onError);
};

export default Api;
