import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';

const LoginButton = () => {
   const { loginWithRedirect } = useAuth0();

   return (
      <Button
         onClick={() => loginWithRedirect()}
         style={{
            marginLeft: 'auto',
         }}
      >
         Log In
      </Button>
   );
};

export default LoginButton;
